import React, { Component } from 'react';
import {
    Spin, Table, Switch, Modal, Row, Col, Select,
    Dropdown, Button, Menu, Checkbox, Input, InputNumber, Tooltip,
    message
} from 'antd';
import plugin from 'plugin';
import RPC from 'api';
import { bindLifecycle } from 'utils/bindLifecycle';
import { Icon, LabelSelect, LabelText} from 'libs';
import { LangConsumer } from 'utils/LangProvider';
import Ability from '../../../../ability';
import WebApp from '../../../../webapp';
import { } from '../../../../common';
import './index.less';
import _ from 'lodash';

class NumberStatScalePlugin extends React.Component {
    /**用来存放组件内状态 */
    constructor (props) {
        super(props);
        this.ComponentName = 'NumberStatConfigPlugin';
        this.videoRef = React.createRef();
        this.containerID = 0;

        this.state = {
            regionShapeId: 0, // 区域规则线的ID
            lineShapeId: 0, // 方向线的ID
            selectShapeId: 0, // 当前选中的智能规则线Id
            selectShapeType: '', // 当前选中的规则类型
            Config: {}, // 当前选中规则的Config配置
            btnStatus: [],
            isVisiblePluginBar: false, // 是否显示插件操作栏
            drawingType: '', // 当前正在绘制的规则
            hidePluginFlag: false,
        };
    }
    componentDidMount () {
        this.addPluginEvent();
    }
    componentDidActivate () {
        let { rule } = this.props;
        setTimeout(() => {
            // 进入页面后重新绘制规则
            this.drawRule();
        }, 700);
        this.addPluginEvent();
    }
    componentWillUnmount () {
        console.log('componentWillUnmount');
        this.leave();
    }
    componentWillUnactivate () {
        console.log('componentWillUnactivate');
        this.leave();
    }
    /**
     * 离开页面触发
     * 1. 清空规则；2.销毁容器；3.取消监听；4.隐藏插件；5.停止拉流
     */
    leave = () => {
        // 清空规则，防止在预览或者其他页面中重新绘制
        plugin.DeleteAllVideoAnalyseShape(this.containerID);
        // 销毁容器
        plugin.ReleaseVideoAnalyseContainer(this.containerID);
        this.containerID = 0;
        plugin.addEvent('VideoAnalyzeConfig', null);
        plugin.hide();
        plugin.close();
    }
    hide = () => {
        plugin.hide();
    }
    cover = () => {
        plugin.cover(this.videoRef.current);
    }
    open = () => {
        plugin.open(1, this.props.channel, 0);
    }
    close = () => {
        plugin.close();
    }
    clear = () => {
        plugin.DeleteAllVideoAnalyseShape(this.containerID);
    }
    /**
     * 当控件隐藏时，显示视频区默认图片
     * @param [Boolean][bool]  控件是否隐藏
     */
    showVideoDefaultImage = (bool) => {
        this.setState({hidePluginFlag : bool});
    }
    addPluginEvent = () => {
        let { $t } = this.props;
        //智能回调
        plugin.addEvent('VideoAnalyzeConfig', (shapeID, data, curState, preState, eventType, eventName, markedName, shapeType) => {
            let { Config } = this.state;
            if (curState === 3) {
                switch (eventName) {
                case 'drawRegion':
                    Config.DetectRegion = data;
                    this.setState({ drawingType: '', regionShapeId: shapeID });
                    break;
                case 'drawLine':
                    Config.DetectLine = data;
                    this.setState({ drawingType: '', lineShapeId: shapeID });
                    plugin.SetVideoAnalyseShapeDirection(this.containerID, shapeID, 0);
                    break;
                }
                console.log(Config, markedName);
                if (preState === 2) { // 新增图形
                    plugin.SetVideoAnalyseContainerTip(this.containerID, $t('com.DrawingIsCompletedTip')); // 绘制完成提示
                }
                this.setState({ selectShapeId: shapeID, selectShapeType: shapeType, Config }); // 设置选中状态
            }
        });
    }
    /**
     * 画规则线
     */
    drawRule = async () => {
        let { rule, $t } = this.props;
        let { regionShapeId, lineShapeId, Config } = this.state;
        // 如果已经有图形窗口，则不需要重新创建
        if (this.containerID === 0) {
            plugin.cover(this.videoRef.current);
            plugin.open(1, this.props.channel, 0);
            if (plugin.isInStalled) {
                await plugin.CreateVideoAnalyseContainer().then(containerID => {
                    plugin.EnableVideoAnalyseContainer(containerID, true);
                    this.containerID = containerID;
                });
            }
        }
        plugin.SetIVSEnable(false); // 关闭智能帧
        if (rule && rule.Config) {
            Config = rule.Config;
            console.log(rule);
            this.setState({ Config, isVisiblePluginBar: true }, () => {
                // 画规则线之前，先删除原有规则线，防止重复
                plugin.DeleteAllVideoAnalyseShape(this.containerID).then(() => {
                    if (rule.Enable) { // 如果使能开启，则正常绘制
                        if (Config.DetectRegion && Config.DetectRegion.length !== 0) { // 存在区域
                            // 绘制区域线
                            plugin.CreateMainVideoAnalyseShape(this.containerID, 'drawRegion', 'Polygon', rule.Name, JSON.stringify({ Polygon: Config.DetectRegion }), '').then(shapeID => {
                                regionShapeId = shapeID;
                                // 使能区域线
                                plugin.EnableVideoAnalyseShape(this.containerID, shapeID, true);
                                // 选中区域线
                                this.selectShape(shapeID, 'Polygon');
                                // 设置按钮样式
                                this.setBtnStatus(0);
                                this.setState({ regionShapeId, drawingType: '' });
                            });
                        } else {
                            // 绘制区域线
                            plugin.CreateMainVideoAnalyseShape(this.containerID, 'drawRegion', 'Polygon', rule.Name, '', '').then(() => {
                                this.setState({ drawingType: 'drawRegion' });
                            });
                        }
                        if (Config.DetectLine && Config.DetectLine.length !== 0) { // 存在方向线
                            // 绘制方向线
                            plugin.CreateMainVideoAnalyseShape(this.containerID, 'drawLine', 'DetectLine', '', JSON.stringify({ DetectLine: Config.DetectLine }), '').then(shapeID => {
                                plugin.SetVideoAnalyseShapeDirection(this.containerID, shapeID, 0);
                                lineShapeId = shapeID;
                                // 使能检测线
                                plugin.EnableVideoAnalyseShape(this.containerID, shapeID, true);
                                // 不选中状态
                                plugin.SelectVideoAnalyseShape(this.containerID, shapeID, false);
                                this.setState({ lineShapeId, drawingType: '' });
                            });
                        } else if (rule.type === 'StereoNumberStat') { // 防止区域内人数统计绘制
                            // 绘制规则
                            plugin.CreateMainVideoAnalyseShape(this.containerID, 'drawLine', 'DetectLine', '', '', '').then(() => {
                                this.setState({ drawingType: 'drawLine' });
                            });
                        }
                    } else { // 如果规则使能关闭，则不绘制图形
                        this.setState({ regionShapeId: 0, lineShapeId: 0 });
                    }
                });
            });
        } else { // 规则已经被删除
            plugin.DeleteAllVideoAnalyseShape(this.containerID);
            // 隐藏插件操作栏
            this.setState({ isVisiblePluginBar: false });
        }
    }
    /**
     * 获取当前选中的规则id
     * @param {number} shapeId 当前选中的规则ID
     * @param {string} type 当前选中的规则类型
     */
    selectShape = (shapeId, type) => {
        let { selectShapeId, selectShapeType } = this.state;
        selectShapeId = shapeId;
        selectShapeType = type;
        this.setState({ selectShapeId, selectShapeType });
    }
    // 画区域规则线
    drawRegionRule = () => {
        let { $t, rule } = this.props;
        let { regionShapeId, selectShapeId, drawingType } = this.state;
        if (rule.Enable) { // 若已经有绘制区域，则选中区域，并置为可编辑
            if (drawingType !== '') {
                message.error($t('com.PleaseDrawShapeTip'));
                return;
            }
            plugin.SelectVideoAnalyseShape(this.containerID, selectShapeId, false); // 灰显上一次选中规则线
            plugin.SelectVideoAnalyseShape(this.containerID, regionShapeId, true);
            this.selectShape(regionShapeId, 'Polygon');
            // 设置按钮样式
            this.setBtnStatus(0);
        } else { // 说明使能关闭
            message.error($t('com.RuleIsntEnableTip'));
        }
    }
    // 画规则线
    drawLineRule = () => {
        let { $t, rule } = this.props;
        let { lineShapeId, selectShapeId, drawingType } = this.state;
        if (rule.Enable) { // 若已经有绘制区域，则选中区域，并置为可编辑
            if (drawingType !== '') {
                message.error($t('com.PleaseDrawShapeTip'));
                return;
            }
            plugin.SelectVideoAnalyseShape(this.containerID, selectShapeId, false); // 灰显上一次选中规则线
            plugin.SelectVideoAnalyseShape(this.containerID, lineShapeId, true);
            this.selectShape(lineShapeId, 'DetectLine');
            // 设置按钮样式
            this.setBtnStatus(1);
        } else { // 说明使能关闭
            message.error($t('com.RuleIsntEnableTip'));
        }
    }
    // 删除选中规则
    deleteShape = () => {
        let { selectShapeId, selectShapeType, Config } = this.state;
        let { $t, rule } = this.props;
        if (selectShapeId !== 0) {
            plugin.DeleteVideoAnalyseShape(this.containerID, selectShapeId);
            if (selectShapeType === 'Polygon') { // 选中规则为区域
                plugin.CreateMainVideoAnalyseShape(this.containerID, 'drawRegion', 'Polygon', rule.Name, '', '').then(() => {
                    plugin.SetVideoAnalyseContainerTip(this.containerID, $t('com.PleaseDrawShapeTip'));
                });
                Config.DetectRegion = [];
                this.setState({ drawingType: 'drawRegion' });
            } else if (selectShapeType === 'DetectLine') { // 选中规则为方向线
                plugin.CreateMainVideoAnalyseShape(this.containerID, 'drawLine', 'DetectLine', '', '', '').then(() => {
                    plugin.SetVideoAnalyseContainerTip(this.containerID, $t('ivs.DrawDirection'));
                });
                Config.DetectLine = [];
                this.setState({ drawingType: 'drawLine' });
            }
            this.setState({ selectShapeId: 0, Config });
        } else {
            message.error($t('com.PleaseDrawShapeTip'));
        }
    }
    /**
     * 设置按钮状态
     */
    setBtnStatus = (index) => {
        let { btnStatus } = this.state;
        btnStatus.forEach((k, v) => {
            btnStatus[v] = false;
        });
        btnStatus[index] = true;
        this.setState({ btnStatus });
    }
    /**
     * 返回智能规则对象的配置，供外部使用
     */
    submit = () => {
        let { Config } = this.state;
        return Config;
    }
    render () {
        let { btnStatus, isVisiblePluginBar,hidePluginFlag } = this.state;
        let { $t, rule } = this.props;
        return (
            <div className='_PtzNumberStatPlugin_'>

                <div className="video-wrap" ref={this.videoRef}>{hidePluginFlag && <div style = {{textAlign : 'center', marginTop : '30%'}}><Icon type = 'videoDefault'/></div>}</div>
                <div className='aitoolsbar-warp'>
                    {isVisiblePluginBar ? <React.Fragment>
                        {/*绘制区域*/}
                        <div
                            className='icon'
                            onClick={() => {
                                if (plugin.isInStalled) {
                                    this.drawRegionRule();
                                }
                            }}
                            style={{ color: btnStatus[0] ? '#1890FF' : '' }}
                        >
                            <Tooltip placement='right' title={$t('com.Rule')}>
                                <Icon type="irregularity" />
                            </Tooltip>
                        </div>
                        {/*绘制方向线*/}
                        {rule && rule.Type === 'NumberStat' ?
                            <div
                                className='icon'
                                onClick={() => {
                                    if (plugin.isInStalled) {
                                        this.drawLineRule();
                                    }
                                }}
                                style={{ color: btnStatus[1] ? '#1890FF' : '' }}
                            >
                                <Tooltip placement='right' title={$t('com.Rule')}>
                                    <Icon type="line" />
                                </Tooltip>
                            </div> : null
                        }
                        {/*删除选中规则线*/}
                        <div className='icon' onClick={() => {
                            if (!plugin.isInStalled) {
                                return;
                            }
                            if (!rule.Enable) {
                                // 没有使能规则提示
                                message.error($t('com.RuleIsntEnableTip'));
                            } else {
                                this.deleteShape();
                            }
                        }}>
                            <Tooltip placement='right' title={$t('com.Delete')}>
                                <Icon type="delete" />
                            </Tooltip>
                        </div>
                    </React.Fragment> : null}
                </div>

            </div>
        );
    }
}

export default LangConsumer(bindLifecycle(NumberStatScalePlugin));